import {ReportColumn} from "../../model/ReportColumn";
import {TranslatorStatisticReportData} from "../../model/TranslatorStatisticReportData";
import {List} from "immutable";
import {ReportCell, ReportRow} from "../../model/ReportRow";
import {ProjectReportData} from "../../model/ProjectReportData";

export function toModelTranslatorStatisticReport(response: ITranslatorStatisticResponse) {
    const rows = response
        .matches
        .map(response => toModelMatch(response));

    const columns = [
        new ReportColumn({field: "match", headerName: "Match"}),
        new ReportColumn({field: "segments", headerName: "Segments"}),
        new ReportColumn({field: "words", headerName: "words"})
    ];

    return new TranslatorStatisticReportData({
        dimension: {
            catFileId: response.catFileId,
            catFileName: response.catFileName,
            languageCode: response.languageCode,
            username: response.username,
            workflowStepId: response.workflowStepId,
            workflowStep: response.workflowStep
        },
        columns: List<ReportColumn>(columns),
        rows: List<ReportRow>(rows)
    });
}

export function toModelProjectReport(response: IProjectsReportResponse) {
    const rows = response
        .matches
        .map(response => toModelMatch(response));

    const columns = [
        new ReportColumn({field: "match", headerName: "Match"}),
        new ReportColumn({field: "segments", headerName: "Segments"}),
        new ReportColumn({field: "words", headerName: "words"})
    ];

    return new ProjectReportData({
        dimension: {
            catFileId: response.catFileId,
            catFileName: response.catFileName,
            languageCode: response.languageCode
        },
        columns: List<ReportColumn>(columns),
        rows: List<ReportRow>(rows)
    });

}

export function toModelMatch(response: IMatchScoreResponse): ReportRow {
    const values = List.of<ReportCell>(
        {value: response.matchScore, field: "match"},
        {value: response.segments, field: "segments"},
        {value: response.words, field: "words"},
    );

    return new ReportRow({values: values});
}

export interface IProjectsReportResponse {
    languageCode: string,
    catFileId: number,
    catFileName: string,
    matches: List<IMatchScoreResponse>
}

export interface ITranslatorStatisticResponse {
    username: string,
    languageCode: string,
    catFileId: number,
    catFileName: string,
    workflowStepId: number,
    workflowStep: string,
    matches: List<IMatchScoreResponse>
}

export interface IMatchScoreResponse {
    matchScore: string,
    segments: number,
    words: number
}
