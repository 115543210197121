import {User} from "../../../model/User";
import {Page} from "../../../model/Page";

export enum UsersListAction {
    SetProjectUsers = "UsersListAction.SetUsersWithPermissions"
}

interface ISetUsersWithPermissions {
    action: UsersListAction.SetProjectUsers,
    users: Page<User>
}

export type UsersListPayload = ISetUsersWithPermissions;

export function setProjectUsers(users: Page<User>): UsersListPayload {
    return {
        action: UsersListAction.SetProjectUsers,
        users: users
    }
}