import {Map, Record} from "immutable";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {IFilter} from "../../../model/Filter";
import {updateWithGridFilterModel} from "../../../utils/ListViewUtils";

interface IProjectLanguagesOperatorsFilter {
    nameOperator: string | null,
}

interface IProjectLanguagesFilter extends  IProjectLanguagesOperatorsFilter{
    name: string | null
}

const ProjectLanguagesFilterRecord = Record<IProjectLanguagesFilter>({
    name: null,
    nameOperator: null
});

export default class ProjectLanguagesFilter extends ProjectLanguagesFilterRecord implements IFilter<ProjectLanguagesFilter> {
    updateWithGridFilterModel(_model: GridFilterModel) {
        return updateWithGridFilterModel<IProjectLanguagesFilter, ProjectLanguagesFilter>(this, _model, AvailableProjectLanguagesFilters);
    }

    updateWithGridSortingModel(_model: GridSortModel) {
        return this;
    }

    toGridFilterModel(): GridFilterModel {
        return {items: []};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }

}


const AvailableProjectLanguagesFilters = Map<string, string>()
    .set('name', 'nameOperator')
