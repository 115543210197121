import {Model} from "../../model/IModel";
import {Autocomplete, Checkbox, ListItemButton, ListItemIcon, ListItemText, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {IFilter} from "../../model/Filter";
import {CommonListActions} from "../../flux/common/list/CommonListActions";
import Immutable from "immutable";

interface IProps<M extends Model, F extends IFilter<F>> {
    value?: M,
    helperText?: string,
    error?: boolean,
    label: string,
    actions: CommonListActions<M, F>,
    getPresentation: (model: M) => string
    onSelect: (models: Immutable.Set<M>) => void
}


// TODO implement as infinite, add selectAll
export default function TextFieldMultiSelect<M extends Model, F extends IFilter<F>>(props: IProps<M, F>) {
    const state = props.actions.state;
    const [options, setOptions] = useState(state.page.list.toArray())
    const [selected, setSelected] = useState(state.selected)

    useEffect(() => {
        const storeListener = props.actions.addListener(() => {
            const state = props.actions.state;
            setOptions(state.page.list.toArray());
            setSelected(state.selected);
        });
        return () => storeListener.remove();
    });

    return (
        <Autocomplete
            size={"small"}
            sx={{maxWidth: 400}}
            id={"select-" + props.label}
            multiple
            options={options}
            disableCloseOnSelect
            getOptionLabel={option => props.getPresentation(option)}
            renderOption={(ignored, option) =>
                <ListItemButton
                    dense
                    onChange={() => {
                        props.actions.toggleSelection(option)
                        props.onSelect(props.actions.selected);
                    }}>
                    <ListItemIcon>
                        <Checkbox
                            checked={state.selected.has(option)}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText primary={props.getPresentation(option)}/>
                </ListItemButton>
            }
            onChange={(_e, values) => {
                const models = Immutable.Set(values.map(v => v as M));
                props.actions.select(models);
                props.onSelect(props.actions.selected);
            }}
            renderInput={params => {
                return <TextField
                    {...params}
                    label={props.label}
                    InputLabelProps={{shrink: true}}
                />
            }}
            fullWidth
            value={options.filter(option => selected.has(option))}
        >
        </Autocomplete>
    )
}

