import {Button, Dialog, DialogTitle, Stack, Tabs} from "@mui/material";
import React from "react";
import {saveUserPermissionsAction} from "../../flux/user-permissions/editor/PermissionsEditorActions";
import {SimpleTab} from "../../globals/CommonComponents";
import {NavigateFunction, Outlet, useNavigate} from "react-router-dom";
import {PermissionsUsersPath} from "../../routes/project/tabs/permissions/PermissionsUsersRoute";
import {PermissionsFilesPath} from "../../routes/project/tabs/permissions/PermissionsFilesRoute";
import {PermissionsWorkflowsPath} from "../../routes/project/tabs/permissions/PermissionsWorkflowsRoute";
import {PermissionsLanguagesPath} from "../../routes/project/tabs/permissions/PermissionsLanguagesRoute";
import {ProjectContextType, useProject} from "../project/ProjectView";

interface IProps {
    open: boolean,
    onClose: () => void,
    onTabChange: (tab: number) => void,
    tab: number
}

export function PermissionEditor(props: IProps) {
    const {project} = useProject();
    const navigate = useNavigate();

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}
                PaperProps={{
                    sx: {width: '80vw', maxWidth: '670px'}
                }}>
            <DialogTitle>Set permissions</DialogTitle>
            <Stack direction={"column"} alignItems={"center"} spacing={1}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Tabs value={props.tab} onChange={(_e, value) =>
                        handleTabChanged(value, props.onTabChange, navigate)}>
                        <SimpleTab label={"Users"}/>
                        <SimpleTab label={"Cat files"}/>
                        <SimpleTab label={"Workflow"}/>
                        <SimpleTab label={"Languages"}/>
                    </Tabs>
                </Stack>
                <Outlet context={{project} satisfies ProjectContextType}/>
            </Stack>
            <Button variant={"contained"}
                    onClick={() => {
                        saveUserPermissionsAction();
                        props.onClose();
                    }}>
                Save
            </Button>
        </Dialog>
    );
}

function handleTabChanged(value: number,
                          onTabChange: (tab: number) => void,
                          navigate: NavigateFunction) {
    onTabChange(value);
    switch (value) {
        case 0:
            navigate(PermissionsUsersPath);
            break;
        case 1:
            navigate(PermissionsFilesPath);
            break;
        case 2:
            navigate(PermissionsWorkflowsPath);
            break;
        case 3:
            navigate(PermissionsLanguagesPath);
            break;
    }
}
