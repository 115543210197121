import Glossary from "../../../model/Glossary";

export enum GlossaryEditorAction {
    SetGlossary = "GlossaryEditorAction.SetGlossary",
    SetExistedGlossary = "GlossaryEditorAction.SetExistedGlossary"
}

interface ISetGlossaryPayload {
    action: GlossaryEditorAction.SetGlossary,
    glossary: Glossary
}

interface ISetIsGlossaryExistedPayload {
    action: GlossaryEditorAction.SetExistedGlossary,
    existed: Glossary | null
}

export type GlossaryEditorPayload = ISetGlossaryPayload
    | ISetIsGlossaryExistedPayload;

export function setEditedGlossary(glossary: Glossary): GlossaryEditorPayload {
    return {
        action: GlossaryEditorAction.SetGlossary,
        glossary: glossary
    }
}

export function setIsGlossaryExistedPayload(existed: Glossary | null): GlossaryEditorPayload {
    return {
        action: GlossaryEditorAction.SetExistedGlossary,
        existed: existed
    }
}
