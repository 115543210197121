import {Record} from "immutable";
import {CatEventTypeModel} from "./CatEventTypeModel";


interface ICatEventGroup {
    id: string,
    date: Date,
    userId: string,
    username: string,
    eventType: CatEventTypeModel,
    projectId: number,
    catFileId: number | null,
    catFileName: string | null,
    targetLanguage: string | null,
    workflowStepId: number | null,
    workflowStepName: string | null,
}

const CatEventGroupRecord = Record<ICatEventGroup>({
    id: "unused",
    catFileId: null,
    catFileName: null,
    date: new Date(),
    eventType: CatEventTypeModel.Undefined,
    projectId: 0,
    userId: "",
    username: "",
    targetLanguage: null,
    workflowStepId: null,
    workflowStepName: null
});

export class CatEventGroup extends CatEventGroupRecord {
}
