import {ReduceStore} from "flux/utils";
import {dispatcher, Payload} from "../../Dispatcher";
import {User} from "../../../model/User";
import {Page} from "../../../model/Page";
import {Record} from "immutable";
import {UsersListAction, UsersListPayload} from "./UsersListPayload";

interface IUsersListState {
    projectUsers: Page<User> | null
}

const UsersListStateFactory = Record<IUsersListState>({
    projectUsers: null
})

class UsersListState extends UsersListStateFactory {
}

export class UsersListStore extends ReduceStore<UsersListState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): UsersListState {
        return new UsersListState();
    }

    reduce(state: UsersListState, payload: UsersListPayload): UsersListState {
        switch (payload.action) {
            case UsersListAction.SetProjectUsers:
                state = state.set("projectUsers", payload.users);
                break;
        }
        return state;
    }
}

const usersListStore = new UsersListStore();
export default usersListStore;