import {buildUrl, dispatchFetchPageError, toParameters} from "../globals/ApiGlobals";
import {List} from "immutable";
import {IUserResponse, toUserModel} from "./model/UserApiModel";
import {User} from "../model/User";
import {Page} from "../model/Page";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import {Pageable} from "../model/Pageable";
import axios, {AxiosResponse} from "axios";
import UsersListFilter from "../flux/users/UsersListFilter";

export function fetchUsers(filter?: UsersListFilter,
                           page?: Pageable,
                           targetEndpoint = endpoint) {
    let url = buildUrl(
        targetEndpoint + "/search",
        {},
        toParameters<UsersListFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IUserResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toUserModel(value));
            return Page
                .fromBackendPage<User>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<User>);
}

export function fetchManagers() {
    return axios
        .get(endpoint + "/managers")
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IUserResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toUserModel(value));
            return Page
                .fromBackendPage<User>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<User>);
}

const endpoint = "/api/common/users";