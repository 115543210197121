import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchFetchPageError} from "../globals/ApiGlobals";
import {List} from 'immutable';
import {Report} from "../model/Report";
import {ProjectReportData} from "../model/ProjectReportData";
import {TranslatorStatisticReportData} from "../model/TranslatorStatisticReportData";
import {
    ITranslatorStatisticResponse,
    IProjectsReportResponse,
    toModelTranslatorStatisticReport,
    toModelProjectReport
} from "./model/ReportDataApiModel";
import {Page} from "../model/Page";
import {EmbeddedResponse} from "../globals/ApiModels";
import TranslatorStatisticReportFilterView from "../components/report/TranslatorStatisticReportFilterView";

export function fetchProjectReportData(report: Report) {
    const dataEndpoint = endpoint + "/project-report/:id/report";
    let url = buildUrl(dataEndpoint, {id: report.id});
    const fetchNoDimension = fetchProjectReportDataPromise(url);

    //TODO: Enable after resolve performance issue
    // url = buildUrl(dataEndpoint, {id: report.id}, {dimensions: "CatFile"});
    // const fetchByCatFile = fetchProjectReportDataPromise(url);
    return Promise
        .all([fetchNoDimension])
        .then(([noDimension]) => {
            return new Page<ProjectReportData>().setList(List(noDimension));
        })
        .catch(dispatchFetchPageError<ProjectReportData>);
}

export function fetchTranslatorStatisticReportData(report: Report) {
    const dataEndpoint = endpoint + "/translator-statistic/:id/report";
    let url = buildUrl(dataEndpoint, {id: report.id});
    const fetchNoDimension = fetchTranslatorStatisticData(url);

    //TODO: Enable after resolve performance issue
    // url = buildUrl(dataEndpoint, {id: report.id}, {dimensions: "CatFile"});
    // const fetchByCatFile = fetchAssigneeReportDataPromise(url)

    return Promise
        .all([fetchNoDimension])
        .then(([noDimension]) => {
            return new Page<TranslatorStatisticReportData>().setList(List(noDimension));
        })
        .catch(dispatchFetchPageError<TranslatorStatisticReportData>);
}

function fetchTranslatorStatisticData(url: string) {
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponse<ITranslatorStatisticResponse[]>>) => {
            return response
                .data
                ._embedded
                .map(value => toModelTranslatorStatisticReport(value));
        });
}

function fetchProjectReportDataPromise(url: string) {
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponse<IProjectsReportResponse[]>>) => {
            return response
                .data
                ._embedded
                .map(value => toModelProjectReport(value));
        });
}

const endpoint = "/api/reports";