import {Record} from "immutable";
import {CatEventTypeModel} from "./CatEventTypeModel";

interface ICatEvent {
    id: string,
    eventObjectId: number,
    eventType: CatEventTypeModel,
    date: Date,
    data: string,
    catFileName: string,
    workflowStepName: string,
    username: string
}

const CatEventRecord = Record<ICatEvent>({
    id: "",
    data: "",
    date: new Date(),
    eventObjectId: 0,
    eventType: CatEventTypeModel.Undefined,
    catFileName: "",
    workflowStepName: "",
    username: "",
});

export class CatEvent extends CatEventRecord {
    constructor(values: Partial<ICatEvent> | Iterable<[string, unknown]>) {
        super(values);
        this._dataAsJson = JSON.parse(this.data);
    }

    private readonly _dataAsJson;

    get bestMatchScore() {
        const tmMatch = this._dataAsJson.bestMatchScore;
        if (tmMatch && tmMatch > 0)
            return `${tmMatch}%`
        return "";
    }

    get dataAsJson() {
        return this._dataAsJson;
    }
}
