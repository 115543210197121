import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {ReactSetter} from "../../globals/Types";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import CatEventGroupFilter from "../../flux/event/CatEventGroupFilter";
import {eventGroupListActions} from "../../flux/event/EventGroupListActions";
import {userListActions} from "../../flux/users/select/UserListActions";
import TextFieldMultiSelect from "../common/TextFieldMultiSelect";
import {eventTypeActions} from "../../flux/event/EventTypeActions";

export default function ActivityFilters() {
    const initialFilterState = eventGroupListActions.state.filter as CatEventGroupFilter;
    const navigate = useNavigate();
    const [filter, setFilter] = useState(initialFilterState);

    useEffect(() => {
        const eventsListener = eventGroupListActions.addListener(() => {
            setFilter(eventGroupListActions.state.filter);
        });

        return () => {
            eventsListener.remove();
        }
    }, []);

    if (!filter)
        return <CircularProgress/>

    return (
        <Stack>
            <Box>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                    <Typography>FILTERS</Typography>
                </Stack>
            </Box>
            <Box mb={2}>
                {drawFileNameFilter(filter, navigate, setFilter)}
            </Box>
            <Box mb={2}>
                <TextFieldMultiSelect
                    onSelect={models => {
                        const updated = filter.set("eventTypes", models.map(v => v.id))
                        updatePage(navigate, updated);
                    }}
                    label={"Event types"}
                    getPresentation={model => `${model.presentation()}`}
                    actions={eventTypeActions}>
                </TextFieldMultiSelect>
            </Box>
            <Box mb={2}>
                <TextFieldMultiSelect
                    onSelect={models => {
                        const updated = filter.set("authors", models.map(v => v.id))
                        updatePage(navigate, updated);
                    }}
                    label={"Authors"}
                    getPresentation={model => `${model.firstName} ${model.lastName}`}
                    actions={userListActions}>
                </TextFieldMultiSelect>
            </Box>
            <Box>
                {drawDatePicker(filter, navigate)}
            </Box>
        </Stack>
    )
}

function updatePage(navigate: NavigateFunction, updatedFilter: CatEventGroupFilter) {
    navigate("?" + updatedFilter.toSearchParams(), {replace: true});
}

function handleOnEnter(event: React.KeyboardEvent<HTMLDivElement>,
                       filter: CatEventGroupFilter,
                       navigate: NavigateFunction) {
    event.stopPropagation();
    if (event.key === "Enter") {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement) {
            activeElement.blur();
            updatePage(navigate, filter);
        }
    }
}

function drawFileNameFilter(filter: CatEventGroupFilter,
                            navigate: NavigateFunction,
                            setFilter: ReactSetter<CatEventGroupFilter>) {
    return (
        <TextField size={"small"}
                   fullWidth
                   placeholder={"File name"}
                   value={filter.fileName ? filter.fileName : ""}
                   onBlur={() => updatePage(navigate, filter)}
                   onChange={e => setFilter(filter.set("fileName", e.target.value))}
                   onKeyDown={e => handleOnEnter(e, filter, navigate)}
        />
    );
}

function drawDatePicker(filter: CatEventGroupFilter,
                        navigate: NavigateFunction,
) {
    return (
        <Stack direction="row" spacing={1} flexWrap="wrap">
            <Box sx={{flexGrow: 1, minWidth: '300px'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack>
                        <Stack direction={"row"} spacing={0.5}>
                            <DateTimePicker label={"Start date"}
                                            ampm={false}
                                            maxDate={filter.to != null ? dayjs(filter.to) : undefined}
                                            onChange={value => handleTimeFilterChanged(
                                                value,
                                                dayjs(filter.to),
                                                filter,
                                                navigate)}
                                            value={dayjs(filter.from)}
                                            sx={{
                                                minWidth: '126px', flex: 1,
                                                '& .MuiInputBase-root': {
                                                    height: '40px',
                                                    '& fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                    '&.Mui-error fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                },


                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        style: {
                                                            fontFamily: 'Roboto',
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                            lineHeight: '24px',
                                                            letterSpacing: '0.15px',
                                                            textAlign: 'left',
                                                        },
                                                    },
                                                    InputLabelProps: {
                                                        style: {
                                                            fontFamily: 'Roboto',
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                            lineHeight: '24px',
                                                            letterSpacing: '0.15px',
                                                            textAlign: 'left',
                                                            color: '#6c757d',
                                                        },
                                                    },
                                                },
                                            }}
                            />
                            <Typography variant="body1"
                                        sx={{
                                            paddingRight: '10px',
                                            paddingLeft: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                            >
                                —
                            </Typography>
                            <DateTimePicker label={"End date"}
                                            ampm={false}
                                            minDate={filter.from != null ? dayjs(filter.from) : undefined}
                                            onChange={value => handleTimeFilterChanged(
                                                dayjs(filter.from),
                                                value,
                                                filter,
                                                navigate)}
                                            value={dayjs(filter.to)}
                                            sx={{
                                                minWidth: '126px', flex: 1,
                                                '& .MuiInputBase-root': {
                                                    height: '40px',
                                                    '& fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                    '&.Mui-error fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },

                                                },
                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        style: {
                                                            fontFamily: 'Roboto',
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                            lineHeight: '24px',
                                                            letterSpacing: '0.15px',
                                                            textAlign: 'left',
                                                        },
                                                    },
                                                    InputLabelProps: {
                                                        style: {
                                                            fontFamily: 'Roboto',
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                            lineHeight: '24px',
                                                            letterSpacing: '0.15px',
                                                            textAlign: 'left',
                                                            color: '#6c757d',
                                                        },
                                                    },
                                                },
                                            }}
                            />
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                            <Button size={"small"} onClick={() =>
                                handleTimeFilterChanged(null, null, filter, navigate)}>
                                Reset
                            </Button>
                        </Stack>
                    </Stack>
                </LocalizationProvider>
            </Box>
        </Stack>
    );
}

function handleTimeFilterChanged(from: Dayjs | null,
                                 to: Dayjs | null,
                                 filter: CatEventGroupFilter,
                                 navigate: NavigateFunction) {
    const updatedFilter = filter
        .set("from", from && from.isValid() ? from.toDate() : null)
        .set("to", to && to.isValid() ? to.toDate() : null);
    updatePage(navigate, updatedFilter);
}