import {TranslatorStatisticReportFilter, ReportFilters} from "../../model/ReportFilters";
import {Container} from "@mui/material";
import TranslatorStatisticReportFilterView from "./TranslatorStatisticReportFilterView";

interface IReportFilterProps {
    filter: ReportFilters
}

export default function ReportFilterView(props: IReportFilterProps) {
    if (props.filter instanceof TranslatorStatisticReportFilter)
        return <TranslatorStatisticReportFilterView/>;

    return <Container/>;
}