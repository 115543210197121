import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, Stack, TextField} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, {RefObject, useEffect, useRef, useState} from "react";
import useFileUpload from "react-use-file-upload";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {FolderZip} from "@mui/icons-material";
import translationMemoryEditorStore from "../../flux/translation-memory/editor/TranslationMemoryEditorStore";
import {
    setEditedTranslationMemoryAction, uploadTranslationMemoryAction
} from "../../flux/translation-memory/editor/TranslationMemoryEditorActions";
import TranslationMemory, {isPending} from "../../model/TranslationMemory";

interface IProps {
    open: boolean,
    onClose: (tm: TranslationMemory | null) => void
}

export default function UploadedTranslationMemoryEditor(props: IProps) {
    const state = translationMemoryEditorStore.getState()
    const [tm, setTm] = useState(state.tm);
    const [existedTm, setExistedTm] = useState(state.existed);
    const [submitProcessing, setSubmitProcessing] = useState(false);
    const openTmFileElement = useRef<HTMLInputElement | null>(null);

    const {clearAllFiles, files, fileNames, handleDragDropEvent, setFiles} = useFileUpload();

    useEffect(() => {
        const tmEditorListener = translationMemoryEditorStore.addListener(() => {
            const state = translationMemoryEditorStore.getState();
            setTm(state.tm);
            setExistedTm(state.existed);
        });
        return () => tmEditorListener.remove();
    }, []);

    useEffect(() => {
        if (fileNames.length > 0 && tm.name === '') {
            const extensionIndex = fileNames[0].lastIndexOf(".");
            const _tmName = fileNames[0].slice(0, extensionIndex);
            setEditedTranslationMemoryAction(tm.set("name", _tmName));
        }
    }, [fileNames]);

    return (
        <Dialog open={props.open} onClose={() => handleClose(clearAllFiles, props.onClose, null)} fullWidth>
            <DialogTitle>Upload Translation Memory</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <TextField color={existedTm ? 'warning' : 'primary'}
                               helperText={existedTm ? "TM will be updated" : ""}
                               label={"Translation memory name"} variant={"standard"}
                               onChange={(e) => {
                                   const name = e.target.value;
                                   setEditedTranslationMemoryAction(tm.set("name", name));
                               }}
                               value={tm.name}>
                    </TextField>
                    <Stack onDragEnter={(e: any) => handleDragDropEvent(e)}
                           onDragOver={(e: any) => handleDragDropEvent(e)}
                           onDrop={e => handleDrop(e, handleDragDropEvent, setFiles)}
                           border={'1px dashed black'} height={'300px'} width={'100%'} alignItems={'center'}
                           justifyContent={'center'} overflow={'hidden'}>
                        {renderDropZoneText(fileNames)}
                        <IconButton color={"primary"} aria-label={"add"} size={"small"}
                                    onClick={() => handleOpenTmClicked(openTmFileElement)}>
                            <Input type="file" inputProps={{accept: '.zip'}} inputRef={openTmFileElement}
                                   onChange={(e: any) => setFiles(e)} style={{display: 'none'}}/>
                            <AddIcon/>
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(clearAllFiles, props.onClose, null)}>Cancel</Button>
                <Button disabled={files.length === 0 || existedTm !== null && isPending(existedTm) || submitProcessing}
                        onClick={() =>
                            handleSubmit(tm, existedTm, files, clearAllFiles, props.onClose, setSubmitProcessing)}>
                    {existedTm ? 'Update' : 'Upload'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function handleOpenTmClicked(openTmFileElement: RefObject<HTMLInputElement>) {
    const current = openTmFileElement.current;
    if (!current)
        return;
    current.click();
}

function handleDrop(e: any, handleDragDropEvent: (e: Event) => void, setFiles: (e: Event) => void) {
    handleDragDropEvent(e);
    setFiles(e);
}

function handleClose(clearAllFiles: () => void,
                     onClose: (tm: TranslationMemory | null) => void,
                     tm: TranslationMemory | null) {
    clearAllFiles();
    onClose(tm);
}

function handleSubmit(tm: TranslationMemory,
                      existedTm: TranslationMemory | null,
                      files: File[],
                      clearAllFiles: () => void,
                      onClose: (tm: TranslationMemory | null) => void,
                      setSubmitProcessing: (v: boolean) => void) {
    setSubmitProcessing(true);
    const uploadingTm = existedTm ? tm.set("id", existedTm.id) : tm;

    uploadTranslationMemoryAction(uploadingTm, files[0]).then(tm => {
        setSubmitProcessing(false);
        handleClose(clearAllFiles, onClose, tm);
    });
}

function renderDropZoneText(fileNames: string[]) {
    if (fileNames.length > 0) {
        return (
            <>
                <FolderZip/>
                <Typography>{fileNames[0]}</Typography>
            </>);
    } else {
        return (
            <>
                <Typography>Drop *.zip file here</Typography>
            </>);
    }
}
