import RollbackFilter from "./RollbackFilter";

export enum RollbackPayloadAction {
    SetInRemain = "RollbackPayloadAction.SetInRemain",
    SetFilter = "RollbackPayloadAction.SetInFilter"
}

interface ISetRollbackInProgressPayload {
    action: RollbackPayloadAction.SetInRemain,
    remain: number
}

interface ISetRollbackFilterPayload {
    action: RollbackPayloadAction.SetFilter,
    filter: RollbackFilter
}

export type RollbackPayload = ISetRollbackInProgressPayload
    | ISetRollbackFilterPayload

export function rollbackRemainPayload(remain: number): RollbackPayload {
    return {
        action: RollbackPayloadAction.SetInRemain,
        remain: remain
    }
}

export function setRollbackFilterPayload(filter: RollbackFilter): RollbackPayload {
    return {
        action: RollbackPayloadAction.SetFilter,
        filter: filter
    }
}
