import React, {useEffect, useState} from "react";
import {DataGridContainer} from "../../globals/CommonComponents";
import {List, Set} from "immutable";
import {Page} from "../../model/Page";
import {DataGrid, GridColDef, GridRowHeightParams, GridRowSelectionModel} from "@mui/x-data-grid";
import {RowsPerPageOptionsDefault} from "../../globals/Constants";
import {Model} from "../../model/IModel";
import {IFilter} from "../../model/Filter";
import {CommonListActions} from "../../flux/common/list/CommonListActions";
import {GridRowHeightReturnValue} from "@mui/x-data-grid/models/params/gridRowParams";
import {useNavigate} from "react-router-dom";

export interface NavigateProperties<F extends IFilter<F>> {
    navigator: (value: F) => string
}

export interface ISelectViewProps<M extends Model, F extends IFilter<F>> {
    actions: CommonListActions<M, F>,
    selection?: boolean,
    getRowId?: (row: M) => string,
    getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue
    columns: GridColDef[],
    navigateProps: NavigateProperties<F> | null,
    initialFilter: F
    useNewSelectionModel?: boolean | false
    height?: number
    width?: number
}

export default function ListView<M extends Model, F extends IFilter<F>>(props: ISelectViewProps<M, F>) {

    const state = props.actions.state;

    const [available, setAvailable] = useState<Page<M>>(state.page);
    const [selected, setSelected] = useState<Set<M>>(state.selected);
    const [selectionModel, setSelectionModel] = useState(state.selectionModel);
    const [isLoading, setIsLoading] = useState(state.isLoading);
    const [filter, setFilter] = useState<F>(state.filter);

    const navigate = useNavigate();

    useEffect(() => {
        const storeListener = props.actions.addListener(() => {
            const state = props.actions.state;
            setSelected(state.selected);
            setSelectionModel(state.selectionModel);
            setIsLoading(state.isLoading);
            setAvailable(state.page);
            setFilter(state.filter);
        });
        return () => storeListener.remove();
    });

    const rows: M[] = generateRows(available);
    const getRowId = props.getRowId ? props.getRowId : (row: M) => row.id;

    let selection = generateSelected(selected, getRowId);

    if (props.useNewSelectionModel) {
        if (selectionModel.isSelectAll && selectionModel.invertedSelection.size === 0 && selected.size >= 0) {
            let selectAllList = selected.concat(available.list);
            props.actions.select(selectAllList);
        }
        if (!selectionModel.isSelectAll && selectionModel.invertedSelection.size === 0 && selected.size > 0) {
            selection = [];
            setSelected(Set<M>())
        }
    }

    return (
        <DataGridContainer height={props.height} width={props.width}>
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: props.initialFilter.toGridSortModel()
                    },
                    filter: {
                        filterModel: props.initialFilter.toGridFilterModel()
                    }
                }}
                rows={rows}
                loading={isLoading}
                getRowHeight={props.getRowHeight ? props.getRowHeight : () => 'auto'}
                getRowId={getRowId}
                columns={props.columns}
                checkboxSelection={props.selection === undefined ? true : props.selection}
                paginationMode={"server"}
                filterMode={"server"}
                sortingMode={"server"}
                pagination
                rowCount={available.totalElements}
                pageSizeOptions={RowsPerPageOptionsDefault}
                paginationModel={{pageSize: available.size, page: available.backendNumber}}
                rowSelectionModel={selection}
                onRowSelectionModelChange={updated =>
                    handleSelectionModelChange(updated, selected, available, props.actions, getRowId)}
                onPaginationModelChange={model =>
                    props.actions.setPage(model.page + 1, model.pageSize)}
                onFilterModelChange={model => {
                    const updated = filter.updateWithGridFilterModel(model);
                    props.actions.fetch(updated);
                    const navigateProps = props.navigateProps;
                    if (navigateProps)
                        navigate(navigateProps.navigator(updated));
                }}
                onSortModelChange={model => {
                    const updated = filter.updateWithGridSortingModel(model);
                    props.actions.fetch(updated)
                    const navigateProps = props.navigateProps;
                    if (navigateProps)
                        navigate(navigateProps.navigator(updated));
                }}
            />
        </DataGridContainer>
    );
}

function generateRows<M extends Model>(available: Page<M>): M[] {
    return available.list.toArray();
}

function generateSelected<M extends Model>(selected: Set<M>,
                                           getRowId: (row: M) => string | number): GridRowSelectionModel {
    return selected.map(value => getRowId(value)).toArray()
}

function handleSelectionModelChange<M extends Model, F extends IFilter<F>>(
    selection: GridRowSelectionModel,
    selected: Set<M>,
    available: Page<M>,
    actions: CommonListActions<M, F>,
    getRowId: (row: M) => string | number): void {

    const availableList: List<M> = available.list;
    const updated = selected
        .concat(availableList)
        .filter(value => {
            const isAvailable = availableList.find(v => v.id === value.id)
            if (!isAvailable)
                return true;
            return selection.find(v => v === getRowId(value));
        });

    const selectedList = availableList.filter(value => {
        return selection.find(v => v === getRowId(value))
    }).map(value => value.id).toArray();

    const unselectedList = availableList.filter(value => {
        return !selection.find(v => v === getRowId(value))
    }).map(value => value.id).toArray();

    actions.select(updated);

    selectedList.forEach(id => actions.selectionModelSelect(id));
    unselectedList.forEach(id => actions.selectionModelDeselect(id));
}