import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError, dispatchFetchPageError, toParameters} from "../globals/ApiGlobals";
import {List} from "immutable";
import {Page} from "../model/Page";
import {Pageable} from "../model/Pageable";
import CatEventGroupFilter from "../flux/event/CatEventGroupFilter";
import {
    IEventGroupResponse,
    IEventResponse, IRollbackState,
    toGroupModel,
    toGroupWebRequest,
    toModel,
    toRollbackState
} from "./model/EventApiModel";
import {CatEventGroup} from "../model/CatEventGroup";
import CatEventFilter from "../flux/event/CatEventFilter";
import {CatEvent} from "../model/CatEvent";
import {CatEventTypeModel} from "../model/CatEventTypeModel";
import {RollbackTaskState} from "../model/RollbackTasksStateEnum";
import {EmbeddedResponse, EmbeddedResponseWithPagination, IResponseData} from "../globals/ApiModels";
import {startOfDay} from "../globals/Utils";

export function fetchEventGroups(filter: CatEventGroupFilter, lastItem?: CatEventGroup) {
    if (lastItem)
        filter = filter.set("to", startOfDay(lastItem.date));

    const url = buildUrl(
        endpoint + "/group/search",
        {},
        toParameters<CatEventGroupFilter>(filter));

    return axios
        .get(url)
        .then((response: AxiosResponse<IEventGroupResponse[]>) => {
            const list = response
                .data
                .map(value => toGroupModel(value))
                // ignore unsupported events
                // may be removed after db cleanup, all unsupported events from event_metadata table
                .filter(value => value.eventType !== CatEventTypeModel.Undefined);

            return List(list);
        })
        .catch(error => {
            dispatchError(error)
            return List<CatEventGroup>();
        });
}

export function fetchEvents(filter?: CatEventFilter, page?: Pageable) {
    const url = buildUrl(
        endpoint + "/search",
        {},
        toParameters<CatEventFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IEventResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toModel(value))
                // ignore unsupported events
                // may be removed after db cleanup, all unsupported events from event_metadata table
                .filter(value => value.eventType !== CatEventTypeModel.Undefined);

            return Page
                .fromBackendPage<CatEvent>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<CatEvent>);
}

export function rollbackGroup(group: CatEventGroup) {
    axios
        .post(endpoint + "/group/revert", toGroupWebRequest(group))
        .catch(dispatchError);
}

export function rollbackEvent(event: CatEvent) {
    const url = buildUrl(
        endpoint + "/:id/revert",
        {id: event.id});
    axios
        .post(url)
        .catch(dispatchError);
}

export function rollbackState(projectId: number) {
    const url = buildUrl(endpoint + "/state", {}, {projectId: projectId});
    return axios
        .get(url)
        .then((response: AxiosResponse<IRollbackState>) => toRollbackState(response.data))
        .catch(error => {
            dispatchError(error);
            return new RollbackTaskState();
        });
}

const endpoint = "/api/projects/events";