import {Stack} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import TopLeftFrame from "../frames/TopLeftFrame";
import Box from "@mui/material/Box";
import MiddleRightFrame from "../frames/MiddleRightFrame";
import BottomRightFrame from "../frames/BottomRightFrame";
import BottomLeftFrame from "../frames/BottomLeftFrame";
import {handleEditorMessageAction} from "../../../flux/segment/editor/SegmentEditorActions";
import languageListStore from "../../../flux/language/list/LanguageListStore";
import SegmentListFilter from "../../../flux/segment/list/SegmentListFilter";
import segmentListStore from "../../../flux/segment/list/SegmentListStore";
import {LanguageList} from "../../../model/LanguageList";
import TopRightFrame from "../frames/TopRightFrame";
import FiltersRightFrame from "../frames/FiltersRightFrame";
import {Pane, ResizablePanes} from "resizable-panes-react";

const styles  =  `
    .vertical-panes {
        height: 100%; 
        width: 100%; 
        display: flex;
    }
    .left-pane {
        height:100%;
        flex-grow: 1;
    }
    .right-pane {
        background-color: #FAFBFC;
        height:100%;
        flex-shrink: 0; 
        min-width:350px;
    }
    .left-horizontal-panes {
        width: 100%;
        height:100%;
    }
    .left-top-pane {
        height:100%;
        flex:100%;
    }
    .left-bottom-pane {
        height:0;
    }
    .right-filter-pane {
        height: 100%; 
    }
    .right-bottom-pane {
        height: 0;
    }
    .right-middle-pane {
        height: 100%; 
        flex:100%;
    }
    .resizer {
        background-color: #a69fa0;
        z-index:100;
    },
`;


export default function EditorView() {
    const [isBottomHidden, setBottomHidden] = useState(false);
    const [isFilterSectionOpen, setIsFilterSectionOpen] = useState(false);
    const [withControls, setWithControls] = useState(false);

    const initialSegmentListFilterState = segmentListStore.getState();
    const initialLanguageListState = languageListStore.getState();

    const [supportedLanguages, setSupportedLanguages] = useState(initialLanguageListState.languages);
    const [filter, setFilter] = useState(initialSegmentListFilterState.filter);

    const rightPaneId = 'right-pane';
    const rightFilterPaneId = 'right-filter-pane';
    const rightMiddlePaneId = 'right-middle-pane';
    const rightBottomPaneId = 'right-bottom-pane';

    // Configuration for right panel
    const MIN_WIDTH_PX = 350;
    const MAX_WIDTH_PX = 500;
    const minSize = MIN_WIDTH_PX / window.innerWidth;
    const maxSize = MAX_WIDTH_PX / window.innerWidth;

    const gridApi: any= useRef(null);
    const onGridReady = (api: any) => {
        gridApi.current = api;
    };
    const onResizeStop = () => {
        if (!gridApi.current) return;
        const sizes = gridApi.current.getSizes();

        if(sizes[rightPaneId] < MIN_WIDTH_PX)
            gridApi.current.setSize(rightPaneId, MIN_WIDTH_PX);
        if(sizes[rightPaneId] > MAX_WIDTH_PX)
            gridApi.current.setSize(rightPaneId, MAX_WIDTH_PX);

    };

    useEffect(() => {
        window.addEventListener('message', handleEditorMessageAction);

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setFilter(state.filter);
            setWithControls(state.withControls);
        });

        const languageListListener = languageListStore.addListener(() => {
            const state = languageListStore.getState();
            setSupportedLanguages(state.languages);
        });

        return () => {
            window.removeEventListener('message', handleEditorMessageAction);
            segmentListListener.remove();
            languageListListener.remove();
        };

    }, []);

    return (
        <Box sx={{height: '100%', paddingTop: '64px'}}>
            <Stack height={'100%'}>
                <ResizablePanes uniqueId="vertical-panes"
                                vertical
                                unit='ratio'
                                className="vertical-panes"
                                resizerSize={1}
                                detectionRadius={40}
                                onResizeStop={onResizeStop}
                                resizerClass="resizer"
                                onReady={onGridReady}
                >
                    {drawLeftContainer(isBottomHidden, setBottomHidden, withControls, gridApi.current)}
                    {drawRightContainer(isFilterSectionOpen, setIsFilterSectionOpen, filter, supportedLanguages,
                        rightFilterPaneId, rightMiddlePaneId, rightBottomPaneId,minSize,maxSize)}
                </ResizablePanes>
                <style>{styles}</style>
            </Stack>
        </Box>
    );
}

function drawLeftContainer(isBottomHidden: boolean, setBottomHidden: React.Dispatch<React.SetStateAction<any>>, withControls: boolean,
                           gridApi: any) {
    return (
        <Pane id="left-pane" minSize={0.6} size={0.7} className="left-pane">
            <ResizablePanes uniqueId="left-horizontal-panes"
                            unit='ratio'
                            unmountOnHide={false}
                            resizerSize={1}
                            detectionRadius={40}
                            resizerClass="resizer"
                            visibility={{
                                'workaround-pane': true,
                                'left-top-pane': true,
                                'left-bottom-pane': !isBottomHidden
                            }}
            >
                {drawWorkaroundContainer()}
                {drawLeftTopContainer(isBottomHidden, setBottomHidden, withControls, gridApi)}
                {drawLeftBottomContainer()}
            </ResizablePanes>
        </Pane>
    );
}

function drawLeftTopContainer(isBottomHidden: boolean, setBottomHidden: React.Dispatch<React.SetStateAction<any>>,
                              withControls: boolean, gridApi: any) {
    return (
        <Pane id="left-top-pane" minSize={0.5} size={0.7} className="left-top-pane">
            <TopLeftFrame
                isBottomHidden={isBottomHidden}
                setIsBottomHidden={setBottomHidden}
                isFilterShown={withControls}
                gridApi={gridApi}
            />
        </Pane>
    );
}

function drawLeftBottomContainer() {
    return (
        <Pane id="left-bottom-pane" maxSize={0.5} size={0.3} className="left-bottom-pane">
            <BottomLeftFrame/>
        </Pane>
    );
}


function drawRightContainer(isFilterSectionOpen: boolean,
                            setIsFilterSectionOpen: React.Dispatch<React.SetStateAction<any>>,
                            filter: SegmentListFilter,
                            supportedLanguages: LanguageList,
                            rightFilterPaneId: string,
                            rightMiddlePaneId: string,
                            rightBottomPaneId: string,
                            minSize:any,
                            maxSize:any
) {
    return (
        <Pane id="right-pane" minSize={minSize} maxSize={maxSize} size={minSize} className="right-pane">
            <ResizablePanes uniqueId="right-horizontal-panes"
                            unit='ratio'
                            unmountOnHide={false}
                            resizerSize={1}
                            detectionRadius={40}
                            resizerClass="resizer"
                            visibility={{
                                'workaround-pane': true,
                                'right-filter-pane': isFilterSectionOpen,
                                'right-middle-pane': !isFilterSectionOpen,
                                'right-bottom-pane': !isFilterSectionOpen
                            }}
            >
                {drawWorkaroundContainer()}
                {drawRightFilterContainer(rightFilterPaneId, isFilterSectionOpen, setIsFilterSectionOpen)}
                {drawRightMiddleContainer(supportedLanguages, filter, rightMiddlePaneId, isFilterSectionOpen, setIsFilterSectionOpen)}
                {drawRightBottomContainer(rightBottomPaneId)}

            </ResizablePanes>
        </Pane>
    );
}

// TODO - That's a workaround for error https://github.com/BipanKishore/resizable-panes/issues/127
// For some reason it occurs only when only 1 pane is visible (filters pane), so additional empty pane is added to avoid it
function drawWorkaroundContainer() {
    return (
        <Pane id="workaround-pane" size={0} maxSize={0} className="workaround-pane"></Pane>
    );
}

function drawRightFilterContainer(rightFilterPaneId: string, isFilterSectionOpen: boolean, setIsFilterSectionOpen: any) {
    return (
        <Pane id={rightFilterPaneId} size={1} className="right-filter-pane"
              resizerClass="resizer">
            <TopRightFrame isFilterSectionOpen={isFilterSectionOpen}
                           openFilterSection={setIsFilterSectionOpen}
            />
            <FiltersRightFrame/>
        </Pane>
    );
}

function drawRightMiddleContainer(supportedLanguages: LanguageList, filter: SegmentListFilter, rightMiddlePaneId: string, isFilterSectionOpen: boolean, setIsFilterSectionOpen: any) {
    return (
        <Pane id={rightMiddlePaneId} size={0.7} minSize={0.4} className="right-middle-pane">
            <TopRightFrame isFilterSectionOpen={isFilterSectionOpen}
                           openFilterSection={setIsFilterSectionOpen}
            />
            <MiddleRightFrame targetLanguage={getTargetLanguage(supportedLanguages, filter)}/>
        </Pane>
    );
}

function drawRightBottomContainer(rightBottomPaneId: string) {
    return (

        <Pane id={rightBottomPaneId} className="right-bottom-pane"
              size={0.3}
              minSize={0.3}
        >
            <BottomRightFrame/>
        </Pane>
    );
}

function getTargetLanguage(supportedLanguages: LanguageList, filter: SegmentListFilter) {
    const foundLanguage = supportedLanguages.findByCode(filter.nonNullLanguage);
    if (!foundLanguage)
        return null;
    return foundLanguage;
}
