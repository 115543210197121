import React, {useEffect, useState} from "react";
import {IconButton, Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import {getGridStringOperators, GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import UploadIcon from "@mui/icons-material/Upload";
import ListView from "../../../common/ListView";
import SelectDialog from "../../../common/SelectDialog";
import {saveGlossariesAction} from "../../../../flux/project/page/ProjectPageActions";
import Project from "../../../../model/Project";
import Immutable from "immutable";
import GlossaryEditor from "../../../glossary/GlossaryEditor";
import {glossarySelectActions} from "../../../../flux/glossary/select/GlossarySelectActions";
import Glossary from "../../../../model/Glossary";
import {setEditedGlossaryAction} from "../../../../flux/glossary/editor/GlossaryEditorActions";
import {glossaryListActions} from "../../../../flux/glossary/list/GlossaryListActions";
import GlossaryFilter from "../../../../flux/glossary/GlossaryFilter";
import {GridRowStringOperatorsDefault} from "../../../../globals/Constants";
import {defaultColumns} from "../../../../utils/ListViewUtils";
import {useProject} from "../../ProjectView";
import {useSearchParams} from "react-router-dom";
import {getProjectGlossariesPath} from "../../../../routes/project/tabs/ProjectGlossariesRoute";

export default function ProjectGlossaries() {
    const {project} = useProject();
    const [searchParams] = useSearchParams();
    const [filter, setFilter]
        = useState<GlossaryFilter | null>(null);

    const [openEditor, setOpenEditor] = useState(false);
    const [openSelect, setOpenSelect] = useState(false);

    useEffect(() => {
        const filter = GlossaryFilter.fromSearchParams(project.id, project.glossaries, searchParams);
        setFilter(filter);
        glossaryListActions.fetch(filter);
    }, [project]);

    return (
        <Stack sx={{height: '100%'}}>
            <GlossaryEditor open={openEditor} onClose={() => setOpenEditor(false)}/>
            <SelectDialog open={openSelect}
                          actions={glossarySelectActions}
                          onClose={() => setOpenSelect(false)}
                          onSelect={(selected: Immutable.Set<Glossary>) => {
                              const glossaries = selected
                                  .map(value => value.id)
                                  .toList()
                                  .concat(project.glossaries);
                              saveGlossariesAction(project.id, glossaries)
                          }}
                          columns={defaultColumns(true)}
                          navigateProps={null}
                          initialFilter={new GlossaryFilter()}/>
            <Stack sx={{height: '100%'}}>
                <Stack direction={"row"}>
                    <IconButton onClick={() => {
                        glossarySelectActions.fetch(new GlossaryFilter());
                        setOpenSelect(true);
                    }}>
                        <AddIcon/>
                    </IconButton>
                    <IconButton onClick={() => {
                        setEditedGlossaryAction(new Glossary());
                        setOpenEditor(true);
                    }}>
                        <UploadIcon/>
                    </IconButton>
                    <IconButton onClick={() => removeGlossary(project, glossaryListActions.selected)}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
                {filter && <ListView actions={glossaryListActions} columns={columns(project)}
                                     navigateProps={{navigator: getProjectGlossariesPath}} initialFilter={filter}/>}
            </Stack>
        </Stack>
    );
}

function columns(project: Project): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: 'Name',
            width: 500,
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        },
        {
            field: 'actions',
            headerName: 'Disable',
            type: 'actions',
            getActions: (params: GridRowParams<Glossary>) => [
                <GridActionsCellItem label={'Delete'} icon={<DeleteIcon/>}
                                     onClick={() => removeGlossary(project, Immutable.Set.of(params.row))}/>
            ]
        }
    ];
}

function removeGlossary(project: Project, toRemove: Immutable.Set<Glossary>) {
    const updated = project
        .glossaries
        .filter(projectGlossary => !toRemove.find(removeGlossary =>
            removeGlossary.id === projectGlossary));
    saveGlossariesAction(project.id, updated);
}