import {Container} from "@mui/material";
import React from "react";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "../common/ListView";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {CatEvent} from "../../model/CatEvent";
import {dateTimeFormat} from "../../globals/Utils";
import {segmentActivityActions} from "../../flux/event/EventListActions";
import CatEventFilter from "../../flux/event/CatEventFilter";
import {styled} from "@mui/material/styles";

const ActivityContainer = styled(Container)({
    overflowY: 'auto',
    height: '100%',
    maxHeight: '100%'
});

export default function ActivityList() {
    return (
        <ActivityContainer maxWidth={false}>
            <ListView actions={segmentActivityActions} columns={columns} selection={false} navigateProps={null}
                      initialFilter={new CatEventFilter()}/>
        </ActivityContainer>
    );
}

const columns: GridColDef[] = [
    {
        field: 'username',
        filterable: false,
        sortable: false,
        headerName: 'Username',
        width: 150,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.username
    },
    {
        field: 'event',
        filterable: false,
        sortable: false,
        headerName: 'Event',
        width: 150,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.eventType.presentation()
    },
    {
        field: 'target',
        filterable: false,
        sortable: false,
        headerName: 'Target',
        width: 350,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.dataAsJson.target,
        cellClassName: 'activity-list--target'
    },
    {
        field: 'workflowStepName',
        filterable: false,
        sortable: false,
        headerName: 'Workflow',
        width: 120,
    },
    {
        field: 'bestMatchScore',
        filterable: false,
        sortable: false,
        headerName: 'TM Match',
        width: 100,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => params.row.bestMatchScore
    },
    {
        field: 'date',
        filterable: false,
        sortable: false,
        headerName: 'Date',
        width: 150,
        valueGetter: (params: GridValueGetterParams<CatEvent>) => dateTimeFormat(params.row.date)
    },
]