import Immutable from "immutable";
import {Tenant} from "./Tenant";

export enum UserGroup {
    Linguists = "Linguists",
    Managers = "Managers",
    VendorManagers = "VendorManagers",
    Admins = "Admins"
}

interface UserType {
    firstName: string,
    lastName: string,
    id: string,
    tenant: Tenant
}

const UserRecord = Immutable.Record<UserType>({
    firstName: '',
    lastName: '',
    id: '',
    tenant: new Tenant(),
});

export class User extends UserRecord {
    get name() {
        return this.firstName + " " + this.lastName;
    }
}