import ListView from "../common/ListView";
import {userListActions} from "../../flux/users/select/UserListActions";
import UsersListFilter from "../../flux/users/UsersListFilter";
import {getPermissionsUsersPath} from "../../routes/project/tabs/permissions/PermissionsUsersRoute";
import {useProject} from "../project/ProjectView";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {GridRowStringOperatorsWithAnyOf} from "../../globals/Constants";
import {getGridStringOperators} from "@mui/x-data-grid";

export default function PermissionsUsersView() {
    const {project} = useProject();
    const [searchParams] = useSearchParams();
    const [filter, setFilter] = useState<UsersListFilter>(UsersListFilter.fromSearchParams(project.id, searchParams));

    useEffect(() => {
        const filter = UsersListFilter.fromSearchParams(project.id, searchParams);
        setFilter(filter);
        userListActions.setFilter(filter).then(() => userListActions.fetch(filter));
    }, [project]);

    return <ListView key={"select-permission-tab0"} actions={userListActions} columns={columns}
                     navigateProps={{navigator: getPermissionsUsersPath}} initialFilter={filter}/>
}

const columns = [{
    field: 'name',
    sortable: true,
    filterable: true,
    headerName: 'Name',
    type: 'string',
    width: 600,
    filterOperators: getGridStringOperators().filter(operator =>
        GridRowStringOperatorsWithAnyOf.includes(operator.value))
}];