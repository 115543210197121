import {ReduceStore} from "flux/utils";
import Immutable from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import {RollbackPayload, RollbackPayloadAction} from "./RollbackPayload";
import RollbackFilter from "./RollbackFilter";

interface IRollbackState {
    remain: number,
    filter: RollbackFilter
}

const RollbackStateFactory = Immutable.Record<IRollbackState>({
    remain: 0,
    filter: new RollbackFilter()
});

export class RollbackState extends RollbackStateFactory {
    get inProgress() {
        return this.remain > 0;
    };
}

export class RollbackStore extends ReduceStore<RollbackState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): RollbackState {
        return new RollbackState();
    }

    reduce(state: RollbackState, payload: RollbackPayload): RollbackState {
        switch (payload.action) {
            case RollbackPayloadAction.SetInRemain:
                return state.set("remain", payload.remain);
            case RollbackPayloadAction.SetFilter:
                return state.set("filter", payload.filter);
            default:
                return state;
        }
    }
}

const rollbackStore = new RollbackStore();
export default rollbackStore;