import {Language} from "../../../model/Language";
import {CommonListActions, promiseWorkaround} from "../../common/list/CommonListActions";
import projectPageStore from "../../project/page/ProjectPageStore";
import {Page} from "../../../model/Page";
import {dispatchUnsupportedOperation} from "../../../globals/ApiGlobals";
import {List} from "immutable";
import {AvailableResourceType} from "../../../model/AvailableResources";
import ProjectLanguagesFilter from "./ProjectLanguagesFilter";

// TODO make std fetch
async function fetchLanguages(filter: ProjectLanguagesFilter) {
    await promiseWorkaround();
    const languages = projectPageStore
        .getState()
        .project
        .targets;

    let filteredLanguages  = languages;
    if(filter.nameOperator !== null){
        filteredLanguages = languages.filter((language) => {
            const name = language.name || "";

            if (filter.nameOperator === "isEmpty") {
                return name.trim() === "";
            }

            if (filter.nameOperator === "isNotEmpty") {
                return name.trim() !== "";
            }

            if (filter.name !== null) {
                const filterName = filter.name.toLowerCase();
                const lowerCaseName = name.toLowerCase();

                switch (filter.nameOperator) {
                    case "contains":
                        return lowerCaseName.includes(filterName);
                    case "equals":
                        return lowerCaseName === filterName;
                    case "startsWith":
                        return lowerCaseName.startsWith(filterName);
                    case "endsWith":
                        return lowerCaseName.endsWith(filterName);
                    default:
                        return true;
                }
            }

            return true;
        });
    }

    return new Page<Language>()
        .setList(filteredLanguages)
        .setSize(filteredLanguages.size);
}

export const projectLanguagesSelectActions = new CommonListActions<Language, ProjectLanguagesFilter>(
    fetchLanguages,
    async () => dispatchUnsupportedOperation(),
    async () => {
        dispatchUnsupportedOperation();
        return List<Language>();
    },
    AvailableResourceType.Projects);