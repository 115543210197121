import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, Stack, TextField} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import glossaryEditorStore from "../../flux/glossary/editor/GlossaryEditorStore";
import useFileUpload from "react-use-file-upload";
import {Description} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import {
    setEditedGlossaryAction,
    updateGlossaryAction,
    uploadGlossaryAction
} from "../../flux/glossary/editor/GlossaryEditorActions";
import Glossary from "../../model/Glossary";

interface IProps {
    open: boolean,
    onClose: () => void
}

export default function GlossaryEditor(props: IProps) {
    const state = glossaryEditorStore.getState()
    const [glossary, setGlossary] = useState(state.glossary);
    const [existedGlossary, setExistedGlossary] = useState(state.existed);
    const openGlossaryFileElement = useRef<HTMLInputElement | null>(null);

    const {files, fileNames, clearAllFiles, handleDragDropEvent, setFiles} = useFileUpload();

    useEffect(() => {
        const glossaryEditorListener = glossaryEditorStore.addListener(() => {
            const state = glossaryEditorStore.getState();
            setGlossary(state.glossary);
            setExistedGlossary(state.existed);
        });

        return () => glossaryEditorListener.remove();
    });

    useEffect(() => {
        if (fileNames.length > 0 && glossary.name === '') {
            const extensionIndex = fileNames[0].lastIndexOf(".");
            const _glossaryName = fileNames[0].slice(0, extensionIndex);
            setEditedGlossaryAction(glossary.set("name", _glossaryName));
        }
    }, [fileNames]);

    return (
        <Dialog open={props.open} onClose={() => handleClose(clearAllFiles, props.onClose)} fullWidth={true}>
            <DialogTitle>Upload Glossary</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <TextField color={existedGlossary ? 'warning' : 'primary'}
                               helperText={existedGlossary ? "Glossary will be updated" : ""}
                               label={"Glossary name"} variant={"standard"}
                               onChange={(e) => {
                                   const name = e.target.value;
                                   setEditedGlossaryAction(glossary.set("name", name));
                               }}
                               value={glossary.name}/>
                    <Stack onDragEnter={(e: any) => handleDragDropEvent(e)}
                           onDragOver={(e: any) => handleDragDropEvent(e)}
                           onDrop={e =>
                               handleDrop(e.nativeEvent, handleDragDropEvent, setFiles)}
                           border={'1px dashed black'} height={'300px'} width={'100%'} alignItems={'center'}
                           justifyContent={'center'} overflow={'hidden'}>
                        {renderDropZoneText(fileNames)}
                        <IconButton color={"primary"} aria-label={"add"} size={"small"}
                                    onClick={() => handleOpenGlossaryClicked(openGlossaryFileElement)}>
                            <Input type="file" inputProps={{accept: '.xlsx'}} inputRef={openGlossaryFileElement}
                                   onChange={(e: any) => setFiles(e)} style={{display: 'none'}}/>
                            <AddIcon/>
                        </IconButton>
                    </Stack>
                </Stack>
                <DialogActions>
                    <Button onClick={() => handleClose(clearAllFiles, props.onClose)}>Cancel</Button>
                    <Button disabled={files.length === 0}
                            onClick={() =>
                                handleSubmit(glossary, files, clearAllFiles, props.onClose, existedGlossary)}>
                        {existedGlossary ? 'Update' : 'Upload'}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

function handleDrop(e: Event, handleDragDropEvent: (e: Event) => void, setFiles: (e: Event) => void) {
    handleDragDropEvent(e);
    setFiles(e);
}

function handleOpenGlossaryClicked(openGlossaryFileElement: React.MutableRefObject<HTMLInputElement | null>) {
    const current = openGlossaryFileElement.current;
    if (!current)
        return;
    current.click();
}

function handleClose(clearAllFiles: () => void, onClose: () => void) {
    clearAllFiles();
    onClose();
}

function handleSubmit(glossary: Glossary,
                      files: File[],
                      clearAllFiles: () => void,
                      onClose: () => void,
                      existedGlossary: Glossary | null) {
    if (existedGlossary)
        updateGlossaryAction(glossary.set("id", existedGlossary.id), files[0]);
    else
        uploadGlossaryAction(glossary, files[0]);
    handleClose(clearAllFiles, onClose);
}

function renderDropZoneText(fileNames: string[]) {
    if (fileNames.length > 0) {
        return (
            <>
                <Description/>
                <Typography>{fileNames[0]}</Typography>
            </>);
    } else {
        return (
            <>
                <Typography>Drop *.xlsx file here</Typography>
            </>);
    }
}