import GroupedActivityList from "./GroupedActivityList";
import {Stack} from "@mui/material";
import {RollbackInProgress} from "./RollbackInProgress";
import ActivityFilters from "./ActivityFilters";

export function ActivityPage() {
    return (
        <Stack sx={{height: '100%'}} direction={"row"}>
            <Stack width={'100%'}>
                <RollbackInProgress/>
                <GroupedActivityList/>
            </Stack>
            <ActivityFilters/>
        </Stack>
    );
}
