import React from 'react';

import App from './App';
import {createRoot} from "react-dom/client";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/source-sans-pro/400.css';
import 'react-resizable-collapsible-grid/dist/index.css';
import keycloak from "./routes/private/Keycloak";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import axios from "axios";
import {User} from "./model/User";
import {setUserAction} from "./flux/auth/AuthActions";

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        //@react-keycloak/web bug workaround: ReactKeycloakProvider - should be first
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{onLoad: 'login-required'}}
            onEvent={keyCloakOnEvent}>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </ReactKeycloakProvider>);
}

function keyCloakOnEvent() {
    keycloak
        .loadUserInfo()
        // @ts-ignore // TODO: remove It
        .then((userInfo) => {
            const casted = userInfo as UserInfoType;
            const user = new User({firstName: casted.given_name, lastName: casted.family_name, id: casted.sub});
            setUserAction(user);
        });

    if (keycloak.authenticated) {
        axios.defaults.headers.common['Authorization'] = `Bearer ` + keycloak.token;
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
    }
}

type UserInfoType = {
    firstName: string,
    lastName: string,
    email: string,
    email_verified: boolean,
    family_name: string,
    given_name: string,
    name: string,
    preferred_username: string,
    sub: string
}


