import PrivateComponent from "../../../components/PrivateComponent";
import {AvailableResourceType} from "../../../model/AvailableResources";
import {ActivityPage} from "../../../components/activity/ActivityPage";
import React from "react";

export default function ActivityRoute() {
    return (
        <PrivateComponent resource={AvailableResourceType.Events} readAllow={true}>
            <ActivityPage/>
        </PrivateComponent>
    );
}

export const ActivityPath = "activity";
export const ActivityId = "Activity";