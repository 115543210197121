import {dispatcher} from "../../Dispatcher";
import {
    selectGlossaryPair,
    selectTMSuggest,
    setGlossaryPairs,
    setSearchResults,
    setSegment
} from "./SegmentEditorPayload";
import {findPairs} from "../../../api/GlossaryApi";
import {fetchTranslations, getSegment, saveTranslation} from "../../../api/SegmentApi";
import {Segment} from "../../../model/Segment";
import {List} from "immutable";
import {fetchCommentsAction} from "../../comment/CommentListActions";
import {Pageable} from "../../../model/Pageable";
import segmentListStore from "../list/SegmentListStore";
import segmentEditorStore from "./SegmentEditorStore";
import GlossaryPairSearchResult from "../../../model/GlossaryPairSearchResult";
import Translation from "../../../model/Translation";
import {segmentActivityActions} from "../../event/EventListActions";
import CatEventFilter from "../../event/CatEventFilter";
import {TargetDataMessage, TargetDataPayload} from "../../../api/windows/TargetDataMessage";
import {GetSegmentsPayload} from "../../../api/windows/GetSegmentsMessage";
import {PostSegmentsDataByIdsMessage, SegmentData} from "../../../api/windows/PostSegmentsDataByIdsMessage";
import {
    editorText,
} from "../../../utils/slate/SlateUtils";
import TranslationMemorySearchResult from "../../../model/TranslationMemorySearchResult";
import slateSerializer from "../../../utils/slate/SlateSerializer";
import projectPageStore from "../../project/page/ProjectPageStore";
import {Descendant, Transforms} from "slate";
import {setIsSegmentSaving, setSegmentListPosition} from "../list/SegmentListPayload";
import {
    isSegmentPositionLoadedAction,
    loadMoreSegmentByPositionAction,
    updateTranslationAction
} from "../list/SegmentListActions";
import {ISearchResult} from "../../../model/ISearchResult";
import {ReactEditor} from "slate-react";

const he = require('he');

export async function findPairsAction(text: string,
                                      sourceLanguage: string,
                                      targetLanguage: string,
                                      glossaryIds: List<number>) {
    const pairs = await findPairs(text, sourceLanguage, targetLanguage, glossaryIds);
    dispatcher.dispatch(setGlossaryPairs(pairs));
}

export async function saveTranslationAction(segment: Segment, text: string) {
    const translation = segment.translation;
    if (!translation)
        return;

    const languageCode = translation.languageCode;

    const isLastStep = translation.nextWorkflowStep.isEmpty();
    if (isLastStep && slateSerializer.decodeText(translation.target) === text)
        return;

    const workflowStepId = isLastStep ? translation.previousWorkflowStep.id : translation.workflowStep.id;
    if (!workflowStepId)
        return;

    await commonSaveTranslationAction(segment.id, languageCode, text, workflowStepId, translation, false);
}

export async function approveTranslationAction(segment: Segment,
                                               text: string | null) {
    const translation = segment.translation;
    if (!translation)
        return false;

    const languageCode = translation.languageCode;

    const decodedTarget = slateSerializer.decodeText(translation.target)
    const isLastStep = translation.nextWorkflowStep.isEmpty();
    if (isLastStep && decodedTarget === text)
        return false;

    const workflowStepId = isLastStep ? translation.previousWorkflowStep.id : translation.nextWorkflowStep.id;
    if (!workflowStepId)
        return false;

    return await commonSaveTranslationAction(segment.id, languageCode, text === null ? decodedTarget : text, workflowStepId, translation, true);
}

export async function insertTranslationAction(sourceEditor: ReactEditor) {
    insertTranslationFromArrayAction(sourceEditor.children);
}

export function replaceTranslationAction(textToReplace: string, replaceWith: string) {
    const segment = segmentEditorStore.getState().segment;
    if (!segment)
        return;

    const editor = segment.translation.editor;
    const currentTranslation = editorText(editor);
    const updated = currentTranslation.replaceAll(textToReplace, replaceWith);
    insertTranslationFromArrayAction(slateSerializer.deserialize(updated).children);
}

function insertTranslationFromArrayAction(children: Descendant[]) {
    const segment = segmentEditorStore.getState().segment;
    if (!segment)
        return;

    const editor = segment.translation.editor;
    Transforms.delete(editor, {at: [0]});
    Transforms.insertNodes(editor, children);
}

export async function updateTranslationFromResourceAction(searchResult: ISearchResult) {
    await insertTranslationAction(searchResult.targetEditor)
}

export async function fetchTranslationsAction(segmentId: number, language: string) {
    const page = await fetchTranslations(segmentId, language);
    dispatcher.dispatch(setSearchResults(page));
}

export function selectGlossaryPairAction(searchResult: GlossaryPairSearchResult | null) {
    dispatcher.dispatch(selectGlossaryPair(searchResult));
}

export function selectTMSuggestAction(tmSuggest: TranslationMemorySearchResult | null) {
    dispatcher.dispatch(selectTMSuggest(tmSuggest));
}

export async function setSegmentAction(segment: Segment) {
    const isNotChanged = segment.id === segmentEditorStore.getState().segment?.id;
    if (isNotChanged)
        return;

    const previousSegment = segmentEditorStore.getState().segment;
    dispatcher.dispatch(setSegment(segment));
    if (previousSegment)
        await saveTranslationAction(previousSegment, editorText(previousSegment.translation.editor));

    const filter = segmentListStore.getState().filter;
    const languageCode = filter.nonNullLanguage;

    await fetchCommentsAction(segment.id, languageCode, new Pageable());

    const eventFilter = new CatEventFilter({
        projectId: filter.projectId,
        segmentId: segment.id,
        languageCode: filter.nonNullLanguage
    });

    await segmentActivityActions.fetch(eventFilter);
    selectTMSuggestAction(null);

    const project = projectPageStore.getState().project;
    await findPairsAction(segment.source, project.source.id, languageCode, project.glossaries);
    await fetchTranslationsAction(segment.id, languageCode);
}

export async function setSegmentByPositionAction(position: number) {
    if (!isSegmentPositionLoadedAction(position))
        await loadMoreSegmentByPositionAction(position);

    const segment = segmentListStore.getState().items.get(position);
    if (!segment)
        return;

    dispatcher.dispatch(setSegmentListPosition(position));
    await setSegmentAction(segment);
}

async function commonSaveTranslationAction(segmentId: number,
                                           languageCode: string,
                                           target: string,
                                           stepId: number,
                                           currentTranslation: Translation,
                                           tryToApprove: boolean) {
    if (slateSerializer.decodeText(currentTranslation.target) === target
        && currentTranslation.workflowStep.id === stepId)
        return false;

    if (segmentListStore.getState().longTasks.isSegmentSaving)
        return false;

    dispatcher.dispatch(setIsSegmentSaving(true));
    let translation = await saveTranslation(segmentId, languageCode, target, stepId);

    // post into smart-edit window
    if (translation) {
        const payload: TargetDataPayload = {};
        payload[segmentId] = {
            text: he.decode(translation.target),
            isConfirmed: translation.nextWorkflowStep.isEmpty()
        }
        postTargetDataMessageAction(payload);
    }

    if (!translation)
        translation = currentTranslation;

    updateTranslationAction(segmentId, translation.withTryToApprove(tryToApprove));

    dispatcher.dispatch(setIsSegmentSaving(false));

    return translation && translation.workflowStep.id === stepId;
}

export async function handleEditorMessageAction(event: MessageEvent) {
    if (event.data.name !== "getSegments")
        return;

    const getSegmentsPayload: GetSegmentsPayload = event.data.payload;
    const segmentsData: SegmentData[] = [];

    await Promise.all(getSegmentsPayload.segmentIds.map(segmentId =>
        getSegment(Number(segmentId), getSegmentsPayload.languageId).then(segment => {
            const translation = segment.translation;

            segmentsData.push({
                segmentId: segment.id,
                sourceText: segment.source,
                translation: he.decode(translation.target),
                isConfirmed: translation.nextWorkflowStep.isEmpty()
            });
        })
    ));
    postSegmentsDataByIdsMessageAction(segmentsData);
}

function postTargetDataMessageAction(payload: TargetDataPayload) {
    if (!window.opener)
        return;
    const response: TargetDataMessage = {
        name: "targetData",
        payload: payload
    }
    window.opener.postMessage(response, "*");
}

function postSegmentsDataByIdsMessageAction(payload: SegmentData[]) {
    if (!window.opener)
        return;
    const response: PostSegmentsDataByIdsMessage = {
        name: "postSegmentsDataByIds",
        payload: payload
    }
    window.opener.postMessage(response, "*");
}
