import {TranslatorStatisticReportFilter} from "../../model/ReportFilters";
import {Container, Stack} from "@mui/material";

import React, {useEffect, useState} from "react";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {setReportListFilterAction} from "../../flux/report/list/ReportListActions";
import reportListStore from "../../flux/report/list/ReportListStore";

export default function TranslatorStatisticReportFilterView() {
    const current = reportListStore.getState().filter;

    const [filter, setFilter] = useState(current);
    const [isValidFrom, setIsValidFrom] = useState(true);
    const [isValidTo, setIsValidTo] = useState(true);

    useEffect(() => {
        const reportListener = reportListStore.addListener(() => {
            const updatedFilter = reportListStore.getState().filter;
            if (updatedFilter instanceof TranslatorStatisticReportFilter)
                setFilter(updatedFilter);
        })

        return () => {
            reportListener.remove();
        }
    });

    if (!(filter instanceof TranslatorStatisticReportFilter))
        return <Container/>

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction={"row"} spacing={1}>
                <DatePicker label="From"
                            value={dayjs(filter.from)}
                            maxDate={filter.to != null ? dayjs(filter.to) : undefined}
                            onChange={value => {
                                let isValidFrom = value?.isValid() ?? false;
                                const updated = filter
                                    .set("from", isValidFrom ? value!.toDate() : null)
                                    .set("isValid", filterIsValid(isValidFrom, isValidTo));
                                setIsValidFrom(isValidFrom);
                                setReportListFilterAction(updated);
                            }}/>
                <DatePicker label="To"
                            value={dayjs(filter.to)}
                            minDate={filter.from != null ? dayjs(filter.from) : undefined}
                            onChange={value => {
                                let isValidTo = value?.isValid() ?? false;
                                const updated = filter
                                    .set("to", isValidTo ? value!.toDate() : null)
                                    .set("isValid", filterIsValid(isValidFrom, isValidTo));
                                setIsValidTo(isValidTo);
                                setReportListFilterAction(updated);
                            }}
                />
            </Stack>
        </LocalizationProvider>
    )
}

function filterIsValid(validFrom: boolean, validTo: boolean) {
    return validFrom && validTo;
}