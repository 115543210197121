import React, {useEffect, useState} from "react";
import {CircularProgress, Container, Stack,} from "@mui/material";
import reportDataStore from "../../flux/report/data/ReportDataStore";
import {DataGridContainer} from "../../globals/CommonComponents";
import SingleReportDataView from "./SingleReportDataView";
import Typography from "@mui/material/Typography";
import {Report, ReportType} from "../../model/Report";
import {ReportDataType} from "../../model/ReportDataType";
import {dateTimeFormat} from "../../globals/Utils";
import {Page} from "../../model/Page";

export default function ReportDataView() {
    const initial = reportDataStore.getState();
    const [data, setData] = useState(initial.data);
    const [isLoading, setIsLoading] = useState(initial.isLoading);
    const [report, setReport] = useState(initial.report);

    useEffect(() => {
        const reportDataListener = reportDataStore.addListener(() => {
            const state = reportDataStore.getState();
            setIsLoading(state.isLoading);
            setData(state.data);
            setReport(state.report);
        });
        return () => reportDataListener.remove();
    }, []);


    let reportData;
    let header = <Container/>;
    if (isLoading) {
        reportData = <CircularProgress/>;
    } else {
        reportData = generateReportData(data);
        header = generateHeader(report);
    }

    return (
        <Container>
            <Stack spacing={1}>
                {header}
                {reportData}
            </Stack>
        </Container>
    );
}

function generateHeader(report: Report) {
    let header;
    switch (report.variety) {
        case ReportType.Project:
            header = "Project statistics report";
            break;
        case ReportType.TranslatorStatistic:
            header = "Translator statistics report.";
            break;
    }
    const subtitle = "created: " + dateTimeFormat(report.created);
    return (
        <Container>
            <Typography variant={"h4"}>
                {header}
            </Typography>
            <Typography variant={"subtitle2"}>
                {subtitle}
            </Typography>
        </Container>
    )
}


function generateReportData(data: Page<ReportDataType>) {
    return (
        <DataGridContainer>
            {data.list.map(value => <SingleReportDataView reportData={value}/>)}
        </DataGridContainer>
    );
}