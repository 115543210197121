import {dispatcher} from "../../Dispatcher";
import {fetchGlossaryByName, updateGlossary, uploadGlossary} from "../../../api/GlossaryApi";
import {glossaryListActions} from "../list/GlossaryListActions";
import Glossary from "../../../model/Glossary";
import {setEditedGlossary, setIsGlossaryExistedPayload} from "./GlossaryEditorPayload";
import {glossarySelectActions} from "../select/GlossarySelectActions";

export async function uploadGlossaryAction(glossary: Glossary, file: File) {
    glossaryListActions.setIsLoading();
    await uploadGlossary(glossary, file);
    await glossaryListActions.refresh();
    await glossarySelectActions.refresh();
    return glossary;
}

export async function updateGlossaryAction(glossary: Glossary, file: File) {
    glossaryListActions.setIsLoading();
    await updateGlossary(glossary, file);
    await glossaryListActions.refresh();
    await glossarySelectActions.refresh();
}

export async function setEditedGlossaryAction(glossary: Glossary) {
    dispatcher.dispatch(setEditedGlossary(glossary));
    await checkGlossaryNameIsExisted(glossary.name);
}

export async function checkGlossaryNameIsExisted(name: string) {
    const existed = await fetchGlossaryByName(name);
    dispatcher.dispatch(setIsGlossaryExistedPayload(existed));
}