import {GlossaryEditorAction, GlossaryEditorPayload} from "./GlossaryEditorPayload";
import {ReduceStore} from "flux/utils";
import {Record} from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import Glossary from "../../../model/Glossary";

interface IGlossaryEditorState {
    glossary: Glossary,
    existed: Glossary | null
}

const GlossaryEditorStateFactory = Record<IGlossaryEditorState>({
    glossary: new Glossary(),
    existed: null
});

class GlossaryEditorState extends GlossaryEditorStateFactory {
}

export class GlossaryEditorStore extends ReduceStore<GlossaryEditorState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): GlossaryEditorState {
        return new GlossaryEditorState();
    }

    reduce(state: GlossaryEditorState, payload: GlossaryEditorPayload): GlossaryEditorState {
        switch (payload.action) {
            case GlossaryEditorAction.SetGlossary:
                return state.set("glossary", payload.glossary);
            case GlossaryEditorAction.SetExistedGlossary:
                return state.set("existed", payload.existed);
        }
        return state;
    }
}

const glossaryEditorStore = new GlossaryEditorStore();
export default glossaryEditorStore;