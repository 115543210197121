import React from "react";
import {Container} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {ReportDataType} from "../../model/ReportDataType";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import {ProjectReportData} from "../../model/ProjectReportData";
import {TranslatorStatisticReportData} from "../../model/TranslatorStatisticReportData";

type ReportComponentProps = {
    reportData: ReportDataType
}

const TruncatedTypography = styled(Typography)({
    wordWrap: 'break-word',
});

export default function SingleReportDataView(props: ReportComponentProps) {
    const reportData = props.reportData;
    const rows = reportData
        .rows
        .map((row, id) => {
            let data = row.values.reduce((obj, cell) => {
                obj[cell.field] = cell.value;
                return obj;
            }, {} as { [key: string]: string | number });
            data["id"] = id;
            return data;
        });


    const columns = reportData
        .columns
        .map(column => {
                return {
                    field: column.field,
                    headerName: column.headerName,
                    width: column.width
                }
            }
        );

    return (
        <Container>
            <TruncatedTypography variant="h5">
                {generateTitle(reportData)}
            </TruncatedTypography>
            <TruncatedTypography variant="subtitle1">
                {generateSubTitle(reportData)}
            </TruncatedTypography>
            <DataGrid rows={rows.toArray()}
                      columns={columns.toArray()}
                      hideFooterPagination={true}
                      hideFooter={true}
                      autoHeight={true}
            />
        </Container>
    );
}

function generateTitle(reportData: ReportDataType) {
    if (reportData instanceof ProjectReportData) {
        const dimension = reportData.get("dimension");
        return generateDimensionString("Target", dimension.languageCode);
    }
    // noinspection SuspiciousTypeOfGuard
    if (reportData instanceof TranslatorStatisticReportData) {
        const dimension = reportData.get("dimension");
        const target = generateDimensionString("Target", dimension.languageCode);
        const workflow = generateDimensionString("Step", dimension.workflowStep);
        return workflow + target;
    }

    return "";
}

function generateSubTitle(reportData: ReportDataType) {
    if (reportData instanceof ProjectReportData) {
        return generateDimensionString("File", reportData.get("dimension").catFileName);
    }
    // noinspection SuspiciousTypeOfGuard
    if (reportData instanceof TranslatorStatisticReportData) {
        const dimension = reportData.get("dimension");
        return generateDimensionString("File", dimension.catFileName) +
            generateDimensionString("Username", dimension.username);
    }

    return "";
}

function generateDimensionString(dimension: string, value: string | null) {
    if (!value)
        return "";

    return `${dimension}: ${value}.`;
}