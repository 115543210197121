import {CommonBuildActions} from "./CommonBuildActions";
import {Build} from "../../../model/Build";
import {SelectionModel} from "../../../model/SelectionModel";
import {buildFile, buildFileTranslations} from "../../../api/FileApi";
import {downloadCatFile, fetchFileBuild} from "../../../api/FileBuildApi";
import SegmentListFilter from "../../segment/list/SegmentListFilter";
import {BuildModel} from "../../../model/BuildModel";
import segmentListStore from "../../segment/list/SegmentListStore";
import {Set} from "immutable";

class CatFileBuildActions<F extends BuildModel> extends CommonBuildActions<F> {
    constructor(buildWithSelection: (filter: F, selection: SelectionModel) => Promise<Build>,
                buildTranslations: (filter: F, selection: SelectionModel) => Promise<Build>,
                fetchBuild: (build: Build) => Promise<Build>,
                download: (build: Build) => void) {
        super((f: F) => buildWithSelection(f, {} as SelectionModel), fetchBuild, download);
        this._buildTranslationsModel = buildTranslations;
        this._buildModelWithSelection = buildWithSelection;
    }

    private readonly _buildTranslationsModel: (filter: F, selection: SelectionModel) => Promise<Build>;
    private readonly _buildModelWithSelection: (filter: F, selection: SelectionModel) => Promise<Build>;

    protected async receiveBuild(filter: F, translationsMode: boolean) {
        const state = segmentListStore.getState();
        const selection = state.selection;
        const updatedSelection =
            !selection.isSelectAll && selection.countSelected(state.page.totalElements) === 0
                ? new SelectionModel({
                    ...selection,
                    isSelectAll: true,
                    invertedSelection: Set(),
                })
                : selection;
        if (translationsMode)
            return await this._buildTranslationsModel(filter, updatedSelection);
        return await this._buildModelWithSelection(filter, updatedSelection);
    }
}

export const catFileBuildActions
    = new CatFileBuildActions<SegmentListFilter>(buildFile, buildFileTranslations, fetchFileBuild, downloadCatFile);