import React from "react";
import {IconButton, Stack, Tooltip} from "@mui/material";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import ListView from "../common/ListView";
import AddIcon from "@mui/icons-material/Add";
import NullFilter from "../../flux/common/NullFilter";
import {getTenantsPath} from "../../routes/admin/TenantsRoute";
import {vendorListActions} from "../../flux/vendor/VendorListActions";
import {Vendor} from "../../model/Vendor";
import PageBreadcrumbs from "../PageBreadcrumbs";
import Box from "@mui/material/Box";
import {getVendorPath} from "../../routes/vendor/VendorRoute";
import {CommonLink} from "../../globals/CommonComponents";
import {useNavigate} from "react-router-dom";
import {NewVendorPath} from "../../routes/vendor/NewVendorRoute";
import DeleteIcon from "@mui/icons-material/Delete";

export default function VendorsList() {
    const navigate = useNavigate();

    return (
        <Stack sx={{height: '100%'}}>
            <PageBreadcrumbs/>
            <Stack sx={{height: '100%'}}>
                <Stack direction={"row"}>
                    <IconButton onClick={() => navigate(NewVendorPath)}>
                        <AddIcon/>
                    </IconButton>
                    <IconButton onClick={() =>  vendorListActions.deleteSelected()}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
                <ListView actions={vendorListActions}
                          columns={columns()}
                          navigateProps={{navigator: getTenantsPath}}
                          selection={true}
                          initialFilter={new NullFilter()}/>
            </Stack>
        </Stack>
    );
}

function columns(): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: 'Name',
            flex: 10,
            filterable: false,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, Vendor>) => {
                const vendor = params.row;
                return (
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <Tooltip title={vendor.name}>
                            <CommonLink to={getVendorPath(vendor.id)}>
                                {vendor.name}
                            </CommonLink>
                        </Tooltip>
                    </Box>
                );
            },
        }
    ];
}