import {CatEventGroup} from "../../model/CatEventGroup";
import CatEventGroupFilter from "./CatEventGroupFilter";
import {fetchEventGroups, rollbackGroup} from "../../api/EventApi";
import {AvailableResourceType} from "../../model/AvailableResources";
import {GroupedListActions} from "../common/list/GroupedListActions";
import {buildEventListActions} from "./EventListActions";
import {CatEvent} from "../../model/CatEvent";
import CatEventFilter from "./CatEventFilter";
import {endOfDay, startOfDay} from "../../globals/Utils";
import {checkRollbackInProgressAction, setRollbackInProgressAction} from "./rollback/RollbackActions";

class EventGroupListActions extends GroupedListActions<
    CatEventGroup,
    CatEventGroupFilter,
    CatEvent,
    CatEventFilter
> {

    constructor() {
        super(fetchEventGroups,
            buildEventListActions,
            group => {
                const filter = eventGroupListActions.state.filter as CatEventGroupFilter
                return new CatEventFilter({
                    catFileId: group.catFileId,
                    fileName: filter.fileName,
                    eventType: group.eventType.type,
                    projectId: group.projectId,
                    languageCode: group.targetLanguage,
                    workflowStepId: group.workflowStepId,
                    userId: group.userId,
                    from: startOfDay(group.date),
                    to: endOfDay(group.date)
                })
            },
            AvailableResourceType.Events
        );
    }

    public rollback(group: CatEventGroup) {
        setRollbackInProgressAction(true);
        rollbackGroup(group);
    }
}

export const eventGroupListActions = new EventGroupListActions();

setInterval(() => {
    if (!eventGroupListActions)
        return;
    checkRollbackInProgressAction(eventGroupListActions.store.getState().filter)
}, 1000);

eventGroupListActions.store.addListener(() => {
    checkRollbackInProgressAction(eventGroupListActions.store.getState().filter)
})