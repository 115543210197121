import {IconButton, Stack, Tab, Tabs, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {List} from "immutable";
import {ReactSetter} from "../../globals/Types";
import {Vendor} from "../../model/Vendor";
import PageBreadcrumbs from "../PageBreadcrumbs";
import ListView from "../common/ListView";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {User, UserGroup} from "../../model/User";
import VendorUsersListFilter from "../../flux/vendor/VendorUsersListFilter";
import vendorEditorStore from "../../flux/vendor/editor/VendorEditorStore";
import {
    addVendorLinguistAction,
    addVendorManagersAction,
    deleteVendorLinguistAction,
    deleteVendorManagersAction,
    saveVendorAction,
    vendorLinguistsListActions,
    vendorManagersListActions
} from "../../flux/vendor/editor/VendorEditorActions";
import AddIcon from "@mui/icons-material/Add";
import SelectDialog from "../common/SelectDialog";
import {defaultColumns} from "../../utils/ListViewUtils";
import {userListActions} from "../../flux/users/select/UserListActions";
import DeleteIcon from "@mui/icons-material/Delete";
import UsersListFilter from "../../flux/users/UsersListFilter";

export function VendorEditor() {
    const vendor = vendorEditorStore.getState().vendor;
    const vendorFilter = new VendorUsersListFilter({vendorId: vendor.id});
    const linguistsFilter = new UsersListFilter({userGroup: UserGroup.Linguists});
    const managersFilter = new UsersListFilter({userGroup: UserGroup.VendorManagers});

    const [name, setName] = useState(vendor.name);
    const [emptyName, setEmptyName] = useState(false)
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [isSelectLinguists, setIsSelectLinguists] = useState(false);
    const [isSelectManagers, setIsSelectManagers] = useState(false);

    useEffect(() => {
        vendorLinguistsListActions.fetch(vendorFilter);
        vendorManagersListActions.fetch(vendorFilter);
    }, []);

    return (
        <Stack direction={"column"} alignItems={"start"} spacing={2} padding={2}>
            <PageBreadcrumbs/>
            <SelectDialog
                open={isSelectLinguists}
                actions={userListActions}
                onClose={() => setIsSelectLinguists(false)}
                columns={defaultColumns(false)}
                navigateProps={null}
                initialFilter={linguistsFilter}
                onSelect={users => addVendorLinguistAction(vendor, users.toList())}>
            </SelectDialog>
            <SelectDialog
                open={isSelectManagers}
                actions={userListActions}
                onClose={() => setIsSelectManagers(false)}
                columns={defaultColumns(false)}
                navigateProps={null}
                initialFilter={managersFilter}
                onSelect={users => addVendorManagersAction(vendor, users.toList())}>
            </SelectDialog>
            <TextField fullWidth={true}
                       id="vendor-name"
                       label="Name"
                       variant="standard"
                       value={name}
                       error={emptyName}
                       helperText={emptyName ? "empty name" : ""}
                       onBlur={() => onSave(name, setEmptyName)}
                       onChange={(e) => {
                           setEmptyName(false);
                           setName(e.target.value);
                       }}/>
            <Tabs value={currentTab} onChange={(_e, value) =>
                handleTabChanged(setCurrentTab, value, vendorFilter)}>
                <Tab label={"Linguists"}/>
                <Tab label={"Managers"}/>
            </Tabs>
            <Stack direction={"row"}>
                <IconButton onClick={() => {
                    if (currentTab === 0) {
                        setIsSelectLinguists(true);
                        userListActions.fetch(linguistsFilter);
                    }
                    else {
                        setIsSelectManagers(true);
                        userListActions.fetch(managersFilter);
                    }
                }}>
                    <AddIcon/>
                </IconButton>
                <IconButton onClick={() => {
                    if (currentTab === 0)
                        deleteVendorLinguistAction(vendor, vendorLinguistsListActions.selected.toList().map(v => v.id));
                    else
                        deleteVendorManagersAction(vendor, vendorManagersListActions.selected.toList().map(v => v.id));

                    userListActions.fetch(new UsersListFilter());
                }}>
                    <DeleteIcon/>
                </IconButton>
            </Stack>

            {drawTab(currentTab, vendor)}
        </Stack>
    );
}

function handleTabChanged(setCurrentTab: React.Dispatch<React.SetStateAction<number>>,
                          value: number,
                          vendorFilter: VendorUsersListFilter) {
    switch (value) {
        case 0:
            vendorLinguistsListActions.forceFetch(vendorFilter);
            break;
        case 1:
            vendorManagersListActions.forceFetch(vendorFilter);
            break;
    }
    setCurrentTab(value);
}

function onSave(name: string, setEmptyName: ReactSetter<boolean>) {
    if (name.trim() === '') {
        setEmptyName(true)
        return;
    }

    const vendor = vendorEditorStore.getState().vendor.set("name", name);
    saveVendorAction(vendor);
}

function drawTab(tab: number, vendor: Vendor) {
    switch (tab) {
        case 0:
            return (
                <ListView actions={vendorLinguistsListActions}
                          columns={columns((id) => deleteVendorManagersAction(vendor, List.of(id)))}
                          navigateProps={null}
                          initialFilter={new VendorUsersListFilter()}>
                </ListView>
            )
        case 1:
            return (
                <ListView actions={vendorManagersListActions}
                          columns={columns((id) => deleteVendorManagersAction(vendor, List.of(id)))}
                          navigateProps={null}
                          initialFilter={new VendorUsersListFilter()}>
                </ListView>
            )
        default:
            return null;
    }
}

function columns(onDelete: (id: string) => {}): GridColDef[] {
    return [
        {
            field: 'firstName',
            headerName: 'First name',
            width: 200,
            filterable: false,
            sortable: false,
            flex: 2,
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 200,
            filterable: false,
            sortable: false,
            flex: 2,
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            flex: 1,
            filterable: false,
            sortable: false,
            getActions: (params: GridRowParams<User>) => [
                <GridActionsCellItem label={'Delete'} icon={<DeleteIcon/>}
                                     onClick={() => {
                                         onDelete(params.row.id);
                                     }}/>
            ]
        }
    ];
}