import {ReduceStore} from "flux/utils";
import {dispatcher, Payload} from "../../Dispatcher";
import {List, Record} from "immutable";
import {CommonBuildAction, CommonBuildPayload} from "./CommonBuildPayload";
import { BuildModel } from "../../../model/BuildModel";

interface ICommonBuildState<M extends BuildModel> {
    building: List<M>;
}

const CommonBuildStateFactory = Record<ICommonBuildState<any>>({
    building: List()
});

class CommonBuildState<M extends BuildModel> extends CommonBuildStateFactory implements ICommonBuildState<M> {
}

export class CommonBuildStore<M extends BuildModel> extends ReduceStore<CommonBuildState<M>, Payload> {
    constructor() {
        super(dispatcher);
        this._storeId = this.getDispatchToken();
    }

    private readonly _storeId: string;

    get storeId() {
        return this._storeId;
    }

    getInitialState(): CommonBuildState<M> {
        return new CommonBuildState();
    }

    reduce(state: CommonBuildState<M>, payload: CommonBuildPayload): CommonBuildState<M> {
        if (!("storeId" in payload))
            return state;

        if (payload.storeId !== this._storeId)
            return state;

        switch (payload.action) {
            case CommonBuildAction.Add:
                return state.set("building", state.building.push(payload.model));
            case CommonBuildAction.Remove:
                return state.set("building", state.building.filter(value => value.id !== payload.model.id));
            default:
                return state;
        }
    }
}