import PrivateComponent from "../../../../components/PrivateComponent";
import {AvailableResourceType} from "../../../../model/AvailableResources";
import PermissionsView from "../../../../components/permissions/PermissionsList";
import React from "react";
import PermissionsFilter from "../../../../flux/user-permissions/PermissionsFilter";
import {ProjectPath} from "../../ProjectRoute";

export default function PermissionsRoute() {
    return (
        <PrivateComponent resource={AvailableResourceType.Permissions} readAllow={true} fullHeight={true}>
            <PermissionsView/>
        </PrivateComponent>
    );
}

export function getPermissionsPath(filter: PermissionsFilter) {
    return ProjectPath.replaceAll(":id", String(filter.projectId)) + "/" + PermissionsPath + "?"
        + filter.toSearchParams();
}

export const PermissionsPath = "permissions";
export const PermissionsId = "Permissions";