import Immutable from "immutable";

interface IRollbackTaskState {
    remain: number,
}

const RollbackTaskStateRecord = Immutable.Record<IRollbackTaskState>({
    remain: 0,
});

export class RollbackTaskState extends RollbackTaskStateRecord {
    get inProgress() {
        return this.remain > 0
    }
}



