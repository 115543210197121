import PermissionsUsersView from "../../../../components/permissions/PermissionsUsersView";
import {ProjectPath} from "../../ProjectRoute";
import UsersListFilter from "../../../../flux/users/UsersListFilter";
import {PermissionsPath} from "./PermissionsRoute";

export default function PermissionsUsersRoute() {
    return <PermissionsUsersView/>
}

export function getPermissionsUsersPath(filter: UsersListFilter) {
    return ProjectPath.replaceAll(":id", String(filter.projectId)) + "/" + PermissionsPath + "/" + PermissionsUsersPath
        + "?" + filter.toSearchParams();
}

export const PermissionsUsersId = "PermissionsUsers";
export const PermissionsUsersPath = "users";